var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list",
    { staticClass: "user-menu" },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-list-item",
                { attrs: { to: { name: "ProfileUpdate" } } },
                [
                  _c("v-list-item-title", [
                    _vm._v(_vm._s(_vm.$t("profile.menu.profile")))
                  ])
                ],
                1
              ),
              _c(
                "v-list-item",
                { attrs: { to: { name: "Orders" } } },
                [
                  _c("v-list-item-title", [
                    _vm._v(_vm._s(_vm.$t("profile.menu.orders")))
                  ])
                ],
                1
              ),
              _c(
                "v-list-item",
                { attrs: { to: { name: "Lists" } } },
                [
                  _c("v-list-item-title", [
                    _vm._v(_vm._s(_vm.$t("profile.menu.lists")))
                  ])
                ],
                1
              ),
              _c(
                "v-list-item",
                { attrs: { to: { name: "Addresses" } } },
                [
                  _c("v-list-item-title", [
                    _vm._v(_vm._s(_vm.$t("profile.menu.addresses")))
                  ])
                ],
                1
              ),
              _c(
                "v-list-item",
                { attrs: { to: { name: "Invoice" } } },
                [
                  _c("v-list-item-title", [
                    _vm._v(_vm._s(_vm.$t("profile.menu.invoice")))
                  ])
                ],
                1
              ),
              _c(
                "v-list-item",
                { attrs: { to: { name: "PaymentDueDate" } } },
                [
                  _c("v-list-item-title", [
                    _vm._v(_vm._s(_vm.$t("profile.menu.paymentDueDate")))
                  ])
                ],
                1
              ),
              _c(
                "v-list-item",
                { attrs: { to: "/profile/news-list" } },
                [
                  _c("v-list-item-title", [
                    _vm._v(_vm._s(_vm.$t("profile.menu.news")))
                  ])
                ],
                1
              ),
              _c(
                "v-list-item",
                {
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.onLogoutClick.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("v-list-item-title", [
                    _vm._v(_vm._s(_vm.$t("profile.menu.logout")))
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }