var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "profile-container" },
    [
      _c("Breadcrumb", { attrs: { items: _vm.breadCrumbs } }),
      _vm.showTcoMenu && _vm.isOroUser
        ? _c(
            "v-tabs",
            { staticClass: "profile-navigation-menu", attrs: { centered: "" } },
            [
              _c("v-tabs-slider"),
              _c(
                "v-tab",
                { attrs: { to: { name: "Receipts" }, value: "Receipts" } },
                [
                  _c(
                    "span",
                    [
                      _c("v-icon", [_vm._v("$receipt")]),
                      _vm._v(_vm._s(_vm.$t("dashboard.menu.receipts")))
                    ],
                    1
                  )
                ]
              ),
              _c(
                "v-tab",
                {
                  attrs: {
                    to: { name: "ProfileSummary" },
                    value: "ProfileSummary"
                  }
                },
                [
                  _c(
                    "span",
                    [
                      _c("v-icon", [_vm._v("$profile")]),
                      _vm._v(_vm._s(_vm.$t("dashboard.menu.summary")))
                    ],
                    1
                  )
                ]
              ),
              _c(
                "v-tab",
                { attrs: { to: { name: "TcoRules" }, value: "TcoRules" } },
                [
                  _c(
                    "span",
                    [
                      _c("v-icon", [_vm._v("$gift")]),
                      _vm._v(_vm._s(_vm.$t("dashboard.menu.rules")))
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-container",
        { staticClass: "profile-section" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "d-none d-md-inline pb-0 d-print-none",
                  attrs: { cols: "12", md: "3", xl: "2" }
                },
                [
                  _c("UserMenu", {
                    staticClass: "grey lighten-1 user-menu",
                    attrs: { user: _vm.getUser }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "9", xl: "10" } },
                [_c("router-view")],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }