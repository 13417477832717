var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "py-0" },
    [
      _c("v-breadcrumbs", {
        attrs: { items: _vm.items, divider: ">" },
        scopedSlots: _vm._u([
          {
            key: "divider",
            fn: function() {
              return [_c("v-icon", [_vm._v(" $chevronRight ")])]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }